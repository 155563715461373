import type { FeatureConfig } from '@seek/hirer-feature-toggle';

import { FEATURE_ADD_ONS } from '../featureKeys';

/* Here is the file where you add feature configs
   Example implementation:
  {
    key: FEATURE_EXAMPLE,
    name: 'Example',
    description: 'Example Feature',
    variants: [true, false],
    fallback: false,
  },
*/

export const featureConfigs: FeatureConfig[] = [
  {
    key: FEATURE_ADD_ONS,
    name: 'Add-Ons',
    description: 'Feature toggle for Add-Ons',
    variants: [true, false],
    fallback: false,
  },
];
